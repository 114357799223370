import { gql } from '@apollo/client';
import { FileFields } from '@fingo/lib/graphql/fileField';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';

export const NON_OPERATIVE_MOVEMENTS = gql`
  query nonOperativeMovements(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $orderBy: String
    $globalFilter: String
    $id_In: [String]
    $countryId: String
  ) {
    nonOperativeMovements(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      orderBy: $orderBy
      globalFilter: $globalFilter
      id_In: $id_In
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          createdAt
          description
          active {
            ...MoneyFields
          }
          passive {
            ...MoneyFields
          }
          result {
            ...MoneyFields
          }
          absoluteAmount @client
          evidence {
            ...FileFields
          }
          user {
            id
            firstName
            lastName
            completeName @client
          }
          movementType {
            id
            name
            code
          }
        }
      }
    }
  }
  ${MoneyFields}
  ${FileFields}
`;

export const NON_OPERATIVE_MOVEMENTS_TYPES = gql`
  query nonOperativeMovementsTypes {
    nonOperativeMovementsTypes {
      id
      name
      code
      lateConciliation
      label @client
      isActive
      countryId
    }
  }
`;
